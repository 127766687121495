import {defineStore} from 'pinia';
import {MarketModel} from "@/app/ecommerce/services/market-service/interfaces/market.model";
import {MarketService} from "@/app/ecommerce/services/market-service/market.service";

interface StateInterface {
  markets: MarketModel[]
}

export const useMarketStore = defineStore('market', {
  state() {
    return {
      markets: [] as MarketModel[]
    };
  },
  getters: {
    getMarkets: (state: StateInterface) => {
      return state.markets;
    }
  },
  actions: {
    async fetchMarkets() {
      this.markets = await MarketService.fetchAllMarkets();
    },
    async addMarket(market: MarketModel) {
      const createdMarket = await MarketService.saveMarket(market);
      this.markets.unshift(createdMarket);
    },
    async editMarket(market: MarketModel) {
      const updatedMarket = await MarketService.updateMarket(market);
      let index = this.markets.map((m) => {
        return m.id;
      }).indexOf(updatedMarket.id);
      this.markets[index] = updatedMarket;
    }
  }
});
