import {httpClient} from "@/app/shared/shared-services/httpClient";
import {environment} from "@/environment";
import {MarketModel} from "@/app/ecommerce/services/market-service/interfaces/market.model";

const URL = `${environment.ecommerceApiBaseUrl}market`;

export const MarketService = {
  async fetchAllMarkets(): Promise<MarketModel[]> {
    return (await httpClient.get(URL)).data.data;
  },

  async saveMarket(market: MarketModel): Promise<MarketModel> {
    return (await httpClient.post(URL, market)).data.data;
  },

  async updateMarket(market: MarketModel): Promise<MarketModel> {
    const url = `${URL}/${market.id}?_method=PUT`;
    return (await httpClient.post(url, market)).data.data;
  },
};
